import React from "react"
import Layout from '../components/Layout';
import {Helmet} from 'react-helmet';
import { Link} from 'gatsby';
import Img from 'gatsby-image';
import {graphql} from 'gatsby';
import {MdDateRange} from 'react-icons/md';
export default ({data}) => 
<div className="index">
    <Helmet>
        <title>หน้าแรก</title>
    </Helmet>
    <Layout>
        <div className="d-flex flex-column">
            <div className="header-page">
                <div className="header-page-text">
                    Coding & Website<br />
                    <Link to="/contactus">
                        <button className="btn btn-contact-large">Contact Us</button>
                    </Link>
                </div>
                <div className="header-page-overay"></div>
                <Img fluid={data.imgHome.childImageSharp.fluid} className="header-page-img" alt="Coding & Website" />
            </div>
            <div className="container mt-2">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-5 text-center">
                        <h3>บริการของเรา</h3>
                    </div>
                    <div className="card mb-3 mt-3">
                        <div className="row no-gutters">
                            <div className="col-md-4">
                                <Img fluid={data.imgTutor.childImageSharp.fluid} alt="สอนเขียนโปรแกรม" />
                            </div>
                            <div className="col-md-8">
                                <div className="card-body">
                                    <h5 className="card-title">สอนเขียนโปรแกรม</h5>
                                    <p className="card-text font-size-22">
                                        การสอนเขียนโปรแกรมเราจะเน้นสอนแบบตัวต่อตัว สิ่งที่เราจะสอนเกี่ยวกับการทำเว็บไซต์เป็นหลัก โดยหลักๆ คือ 
                                        &nbsp;<b>HTML CSS JavaScript jQuery ASP.NET Core(C#) และ Database(Sql Server)</b>
                                    </p>
                                    <Link to="/tutor">
                                        <button type="button" className="btn btn-small">รายละเอียดเพิ่มเติม</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="row no-gutters">
                            <div className="col-md-4">
                                <Img fluid={data.imgIndexMakeWeb.childImageSharp.fluid} alt="ทำเว็บไซต์" />
                            </div>
                            <div className="col-md-8">
                                <div className="card-body">
                                    <h5 className="card-title">ทำเว็บไซต์</h5>
                                    <p className="card-text font-size-22">
                                        รับออกแบบและพัฒนาเว็บไซต์ทุกรูปแบบไม่ว่าจะเป็น เว็บไซต์โปรไฟล์บริษัท ห้างร้านต่างๆ โปรไฟล์ส่วนตัว 
                                        หรืออื่นๆ ตามความต้องการของลูกค้า
                                    </p>
                                    <Link to="/makeweb">
                                        <button type="button" className="btn btn-small">รายละเอียดเพิ่มเติม</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row flex-column last-blog">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                        <h3>บทความล่าสุด</h3>
                    </div>
                    <div className="container-blog">
                        {
                            data.allMarkdownRemark.edges.map( ({node},index) => {
                                let frontmatter = node.frontmatter
                                return (
                                    <Link key={index} to={node.fields.slug} className="card-blog m-2">
                                        <div className="card-blog-image">
                                            <Img fluid={frontmatter.image.childImageSharp.fluid} />
                                        </div>
                                        <div className="col-md-12 d-flex justify-content-center">
                                            <div className="card-blog-number">
                                                {++index}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <h5 className="text-center">{frontmatter.title}</h5>
                                        </div>
                                        <p style={{fontSize:'19px'}} className="indent-50">
                                            {node.excerpt}
                                        </p>
                                        <div><MdDateRange /> {frontmatter.date}</div>
                                    </Link>
                                )
                            })
                        } 
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <Link to="/blog">
                                <button type="button" className="btn btn-base">บทความเพิ่มเติม</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</div>

export const query = graphql`
query{
    allMarkdownRemark(sort: { fields: frontmatter___date order: DESC }, limit: 3) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    imgHome:file(relativePath: {eq: "home.jpg"}) {
        childImageSharp {
            fluid(quality:100){
                ...GatsbyImageSharpFluid
            }
        }
      }
      imgTutor:file(relativePath: {eq: "tutor.jpg"}) {
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgIndexMakeWeb:file(relativePath: {eq: "index-makeweb.jpg"}) {
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      }
  }  
`


